<template lang="pug">
#app
  survey
  //- login(v-else)
</template>

<script>
import { mapGetters } from 'vuex';
import Login from './components/Login.vue'
import Survey from './components/Survey.vue'

export default {
  name: 'App',
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    Login,
    Survey
  },
  created () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      this.$store.commit('SET_USER', { user: user })
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#explora-btn {
  border-radius: 25px;
  &.primary {
    background-color: #303133;
    color: white;
  }
  &.secondary {
    color: #303133;
    border: 0px;
  }
}
</style>
