<template lang="pug">
#Login
  .login-box
    .logo
      img(src="../img/logo-2.png", alt="")
      h2 ENCUESTA EXPLORA
    form(@submit.prevent="login")
      div Usuario:
      el-input.login-input(placeholder="Ingresa DNI", v-model="dni")
      div Contraseña:
      el-input.login-input(placeholder="Ingresa contraseña", type="password", v-model="password")
      .btn
        el-button#explora-btn.primary(:loading="loading", type="primary", :disabled="dni === '' || password === ''", native-type="submit")
          .el-icon-top(style="font-weight: bold;")
          span Iniciar Sesión
</template>
<script lang="ts">
import gql from 'graphql-tag'
import { onLogin } from '../vue-apollo.js'

export default {
  data () {
    return {
      dni: '',
      password: '',
      loading: false
    }
  },
  methods: {
    async login () {
      this.loading = true
      await this.$apollo.mutate({
        mutation: gql`
          mutation($username: String!, $password: String!) {
            Login(username: $username, password: $password) {
              profile {
                name
                userName
                positionCode
                email
                permissions
                hotels {
                  id
                  name
                  code
                }
              }
            }
          }
        `,
        client: 'exploraPublic',
        variables: {
          username: this.dni,
          password: this.password
        }
      })
        .then(async ({ data }) => {
          console.log(data.Login.profile.positionCode)
          if (data.Login.profile.positionCode === 'ENC' || data.Login.profile.positionCode === 'ADM') {
            this.loading = false
            const apolloClient = this.$apollo.provider.defaultClient
            await onLogin(apolloClient, true)
            this.$store.commit('SET_USER', { user: data.Login.profile })
            console.log('logged!!')
          } else {
            this.loading = false
            this.$toasted.show('El usuario debe ser de encuesta o administrador', {
              theme: 'bubble',
              position: 'top-right',
              duration: 5000
            })
          }
        })
        .catch((err) => {
          const exception = { err }
          this.loading = false
          this.$toasted.show(exception.err.graphQLErrors[0].code, {
            theme: 'bubble',
            position: 'top-right',
            duration: 5000
          })
        })
    }
  }
}
</script>
<style lang="scss">
#Login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 200px;
  text-align: left;
  .login-input {
    margin-bottom: 24px;
    width: 100%;
  }
  .login-box {
    width: 80%;
    max-width: 500px;
  }
  .logo {
    width: 100%;
    text-align: center;
    img {
      width: 40%;
      margin-bottom: 32px;
    }
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.toasted.bubble {
  background-color: #e82d4a !important;
}
</style>
