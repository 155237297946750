import Vue from 'vue'
import App from './App.vue'
import store from './store'
import { createProvider } from './vue-apollo'
import ElementUI from 'element-ui'
import Toasted from 'vue-toasted'
import 'element-ui/lib/theme-chalk/index.css'
import '../plugins/fontAwesome'
import VueRouter from 'vue-router'
import router from './router'


Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(Toasted)
Vue.use(VueRouter)

new Vue({
  store,
  render: h => h(App),
  router,
  apolloProvider: createProvider()
}).$mount('#app')
