import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'

export const saveSurveyResponse = async ({ score, commentary, surveyType, token }) => {
  const response = await apolloClient.mutate({
    mutation: gql`
      mutation($score: String!, $commentary: String!, $surveyType: String!, $token: String!) {
        SaveSurveyResponse(score: $score, commentary: $commentary, surveyType: $surveyType, token: $token) {
          result
        }
      }
    `,
    client: 'exploraPublic',
    variables: { score, commentary, surveyType, token },
  })

  return response.data
}
