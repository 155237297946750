<template lang="pug">
#Survey
  .logo
    img(src="../img/logo-2.png", alt="")
  .container
    .center-container(v-if="step === 1")
      span
        .question-txt ¿CÓMO EVALÚAS NUESTRO MENÚ?
        .question-txt.cursive HOW WOULD YOU EVALUATE OUR MENU?
      .icons
        input#BAD.input-hidden(type='radio' value='BAD' v-model='checked')
        label(for='BAD')
          font-awesome-icon(:icon="['far', 'face-frown']" style='color: #d11515;' :size="checked === 'BAD' ? '6x' : '5x'")
        input#REGULAR.input-hidden(type='radio' value='REGULAR' v-model='checked')
        label(for='REGULAR')
          font-awesome-icon(:icon="['far', 'face-meh']" style='color: #e18437;' :size="checked === 'REGULAR' ? '6x' : '5x'")
        input#GOOD.input-hidden(type='radio' value='GOOD' v-model='checked')
        label(for='GOOD')
          font-awesome-icon(:icon="['far', 'face-smile']" style='color: #40ce36;' :size="checked === 'GOOD' ? '6x' : '5x'")
      .btn
        el-button#explora-btn.primary(type="primary", native-type="submit", :disabled="checked === ''" @click="nextStep") SIGUIENTE / NEXT
    .text-box(v-if="step === 2")
      .question-txt AYUDANOS A MEJORAR COMPARTIENDO LAS RAZONES DE TU EVALUACIÓN.
      .question-txt.cursive HELP US IMPROVE BY SHARING THE REASONS FOR YOUR EVALUATION.
      el-input(type='textarea' :rows='6' v-model='comment')
      .btn
        el-button#explora-btn.primary(type="primary", native-type="submit", :disabled="checked === ''" @click="nextStep") ENVIAR / SEND
    .final-msj(v-if="step === 3 && error !== null && error === false")
      font-awesome-icon.check-icon(:icon="['far', 'circle-check']")
      .question-txt GRACIAS POR CONTESTAR ESTA ENCUESTA.
      .question-txt.cursive THANK YOU FOR ANSWERING THIS SURVEY.
    .final-msj(v-if="step === 3 && error !== null && error === true")
      font-awesome-icon.bad-icon(:icon="['far', 'times-circle']")
      .question-txt LO SENTIMOS, OCURRIÓ UN ERROR AL GUARDAR, POR FAVOR INTENTA MÁS TARDE.
      .question-txt.cursive SORRY, AN ERROR OCCURRED WHILE SAVING, PLEASE TRY AGAIN LATER.
</template>
<script lang="ts">
import { saveSurveyResponse } from '../store/SurveyApolloMutations.js'

export default {
  data () {
    return {
      step: 1,
      error: null,
      comment: '',
      checked: ''
    }
  },
  methods: {
    nextStep () {
      this.step = this.step === 1 ? 2 : 3
    },
  },
  watch: {
    async step () {
      // local gAAAAABlrku0Uh0qbG-YWZXVF1wc5HD8t_HklnI5vvaXRHwIJ9MTandCPpAAtLYELIA4H8oMAKneYd_Hr67YIKkFbSe9_dtL5Q==
      // produccion gAAAAABlro9JMn5xX7tXGDkRDgXhwfap5AUo0qASnzpbg5pwEY9F_zl3064NK6bZbs-Xyz9rzvkceXLjNkDhsIebc6Q5vrc8Jw==
      if (this.step === 3) {
        const resp = await saveSurveyResponse({ score: this.checked, commentary: this.comment, surveyType: 'DINNING_ROOM', token: this.$route.params.token })
        console.log(resp)
        this.error = !resp.SaveSurveyResponse.result
        this.comment = ''
        this.checked = ''
        // setTimeout(() => {
        //   this.step = 1
        // }, 6000);
      }
    }
  }
}
</script>
<style lang="scss">
#Survey {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  .center-container {
    height: max-content;
  }
  .logo {
    width: 100%;
    text-align: right;
    img {
      width: 100px;
      margin-bottom: 32px;
    }
  }
  .question-txt {
    font-size: 20px;
    &.cursive {
      font-style: italic;
      margin-top: 16px;
    }
  }
  .icons {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: space-evenly;
    margin-top: 32px;
  }
  .text-box {
    margin-top: 52px;
    padding: 0 32px;
    width: 80%;
  }
  .el-textarea__inner {
    border: 2px solid black;
    font-family: Arial;
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
  .input-hidden {
    position: absolute;
    left: -9999px;
  }
  .fa-6x {
    transition: 500ms;
  }
  .fa-5x {
    transition: 500ms;
  }
  .check-icon {
    font-size: 100px;
    color: #39dc39;
    margin-bottom: 16px;
  }
  .bad-icon {
    font-size: 100px;
    color: #ce3b3b;
    margin-bottom: 16px;
  }
}
</style>
